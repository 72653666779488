
  import ListMixin from "../Mixin/ListMixin";
  // import DynamicTable from "../Forms/DynamicTable";
  import Table from "@/components/Table/Components/Table.vue"
  import {useTablesStore} from "@/components/Table/Store/TableStore"
  import store from "@/store"
  import { uuid } from "vue-uuid";

  export default {
    name: "DynamicList",
    mixins: [ListMixin],
    components: { 
      // DynamicTable, 
      Table
    },
    props: {
      dynModuleName: null,
      isPlanBoardMode: null,
      exData: null,
      exDynFieldsAll: null,
      onRowClickOver: null,
      onRowDblClickOver: null,
    },
    data: () => ({
      title: '',
      filterByName: "",
      showTableRefresh: 0,
      filtersSelectModel: null,
      showAddFilter: false,
      filterSave: {name: '', shared: false, locked: false, default: false, columns: [], filters: {}, pagination: {rowsPerPage : 10}},
      filterEditName: '',
      filterEditShared: '',
      filterEditLocked: '',
      filterEditSelect: {},
      showHeader: true,
      onRowDblClickOverDo: null,
      tableKey: null,
      filterLoading: false,
      doRequest: 0,
    }),
    mounted() {
      this.tableKey = uuid.v4();
    },
    methods: {},
    computed: {
      showFormSettingLoaded() {
        return true;
      },
      hiddenParts() {
        return {};
      }
    },
    watch: {
      '$route.params.moduleName': {
        handler() {
          this.tableKey = uuid.v4();
          this.tableStore = useTablesStore();
          this.tableStore.cachedTableViews[this.$route.params.moduleName] = null
          this.tableStore.currentPage = {}
          this.tableStore.getTableViewByID(this.$route.params.moduleName, store.state.settings.lastUsedTableViews[this.$route.params.moduleName])
        }
      }
    }
  };
