
import {mapActions, mapState} from "vuex";
import debounce from "lodash/debounce";

export default {
  data: () => ({
    filtersSelectModel: null,
  }),
  mounted() {
    this.saveAutoSavePagination = debounce(this.saveAutoSavePaginationDebounce, 500);
  },
  beforeDestroy() {
    this.saveAutoSavePagination.cancel()
  },
  methods: {
    ...mapActions('settings', ["saveListFilter", "deleteListFilter"]),
    ...mapActions('settings', ["getListSettings", "setListSettings", "saveListFilter", "cleanListSettings"]),
    ...mapActions('settings', ["rememberTheCurrentList"]),
    // save pagination from the dynamic table to the model only
    saveAutoSavePagination() {
      // was assigned as debounce
    },
    saveAutoSavePaginationDebounce(data) {



      const {rowsPerPage, sortBy, descending, page, filterData, columnOptionsSelected} = data;
      let dataChanged = false;
      let pageChanged = false;

      if (this.currentListPagination?.filter?.columnOptionsSelected && columnOptionsSelected && JSON.stringify(this.currentListPagination?.filter?.columnOptionsSelected) !== JSON.stringify(columnOptionsSelected)) {

        dataChanged = true;
      }

      if (this.currentListPagination?.filter?.filterData && filterData && JSON.stringify(this.currentListPagination?.filter?.filterData) !== JSON.stringify(filterData)) {

        dataChanged = true;
      }

      const pagination = {rowsPerPage, sortBy, descending};
      const pagination2 = {... this.currentListPagination?.pagination};


      if (Object.keys(pagination2).length && page !== pagination2.page) {

        pageChanged = true;
      }

      delete pagination2.page;
      if (Object.keys(pagination2).length && JSON.stringify(pagination) !== JSON.stringify(pagination2)) {

        dataChanged = true;
      }

      if (dataChanged || pageChanged || !Object.keys(pagination2).length) {
        this.storeCurrentPagination(data);
      }

      this.filtersSelectModel.pagination = pagination;
      this.filtersSelectModel.filters = {columnOptionsSelected, filterData};

      if (dataChanged) {
        this.saveColumnSettings('autosave');
      }
    },
    storeCurrentPagination(data) {
      const {rowsPerPage, sortBy, descending, page, filterData, columnOptionsSelected, searchAfterByPage, savedRowsNumber} = data;
      const module = this.moduleToSaveSettings;
      const pagination = {rowsPerPage, sortBy, descending, page};
      const filter = {filterData, columnOptionsSelected};

      this.rememberTheCurrentList({pagination, filter, searchAfterByPage, module, savedRowsNumber});
    },
    // save filter by (v) and all others
    // types: 'filter', 'column', 'autosave'
    saveColumnSettings(type) {


      if (!type) {
        console.error('saveColumnSettings no type!');
      }

      const settings = {
        module: this.moduleToSaveSettings,
        filter: JSON.parse(JSON.stringify(this.filtersSelectModel)),
      };

      // do not auto save the filters
      if (type === 'autosave') {
        if (settings.filter.filters) {
          delete settings.filter.filters;
        }
      }

      // get filters from the store
      if (type === 'filter') {
        settings.filter.filters = this.filteredDataSaved;
      }

      // no save filters for default filter
      if(this.filtersSelectModel && this.filtersSelectModel.default) {
        settings.filter.filters = {};
      }


      this.saveListFilter(settings).then(() => {

        //this.getListSettings(this.moduleToSaveSettings);
      });
    },
    // select the filter
    async applyFilters(filter) {
      this.filterLoading = true;
      this.rememberTheCurrentList({});
      await this.setListSettings({module: this.moduleToSaveSettings, data: { selectedFilterID : filter.ID }});
      await this.getListSettings(this.moduleToSaveSettings);
      this.filterLoading = false;
    },
  },
  watch: {
    getDefaultFilter() {
      if (this.selectedFilter && this.selectedFilter.ID) {
        this.filtersSelectModel = this.selectedFilter;
        this.doRequest++;
      } else {
        if (this.getDefaultFilter && (this.getDefaultFilter.ID || this.getDefaultFilter.pagination)) {
          this.filtersSelectModel = this.getDefaultFilter;
          this.doRequest++;
        } else {
          this.filtersSelectModel = null;
        }
      }
    },
    'filtersSelectModel': {
      handler(value) {
        //TODO what's the use of this function?
        console.log('')
      },
    },
    savedPagination(pagination) {
      if (pagination) {
        this.oldRowsPerPage = pagination.rowsPerPage;
        this.oldSortBy = pagination.sortBy;
        this.oldDescending = pagination.descending;
      }
    }
  },
  computed: {
    ...mapState("settings", ["listSettings", "userFormsSettings"]),
    ...mapState("settings", ["currentListPagination"]),
    savedPagination() {
      let curPagination;
      if (this.currentListPagination && this.currentListPagination.module === this.moduleToSaveSettings) {
        curPagination = this.currentListPagination.pagination;

      } else {
        curPagination = this.filtersSelectModel ? this.filtersSelectModel.pagination : null;
      }
      return this.filtersSelectModel ? curPagination : null;
    },
    cursorDataSaved() {
      let searchAfterByPage = {};
      if (this.currentListPagination && this.currentListPagination.module === this.moduleToSaveSettings) {
        searchAfterByPage = this.currentListPagination.searchAfterByPage;

      }
      return this.filtersSelectModel ? searchAfterByPage : null;
    },
    rowsNumberDataSaved() {
      let rowsNumberDataSaved = 0;
      if (this.currentListPagination && this.currentListPagination.module === this.moduleToSaveSettings) {
        rowsNumberDataSaved = this.currentListPagination.savedRowsNumber;
      }
      return this.filtersSelectModel ? rowsNumberDataSaved : null;
    },
    filteredDataSaved() {
      let curFilter;
      if (this.currentListPagination && this.currentListPagination.module === this.moduleToSaveSettings
        && this.currentListPagination.filter) {
        curFilter = this.currentListPagination.filter;

      } else {
        curFilter = this.filtersSelectModel ? this.filtersSelectModel.filters : {};
      }
      return this.filtersSelectModel ? curFilter : {};
    },
    getFilterData() {
      return this.listSettings ? this.listSettings[this.moduleToSaveSettings] : {};
    },
    getDefaultFilter() {
      return this.getFilterData && this.getFilterData.defaultFilter ? this.getFilterData.defaultFilter : {};
    },
    getFilters() {
      return this.getFilterData && this.getFilterData.filters ? this.getFilterData.filters : [];
    },
    selectedFilter() {
      return this.getFilterData ? this.getFilterData.selectedFilter : null;
    },
  },

};
