
import { mapState } from "vuex";
import ListValues from "@/components/Mixin/ListValues";
import moment from "moment";
import store from "../../store";
import AbModel from '@/components/Models/AbModel'
import RBAC from "@/components/Settings/Sections/UserManagement/RoleManagement/Models/RBAC";
import ListFilters from "@/components/Mixin/ListFilters";
import sortList from "@/common/helpers/utilities"

export default {
  mixins: [ListFilters],
  data: () => ({
    selected: [],
    deleteBtnVisible: false,
    deleteFormsModal: false,
    togleRefreshList: null,
    showDropdownSettings: false,
    columnSortMode: false,
    columnSortEnabled: false,
    guard: null,
  }),
  methods: {
    initRBAC() {
      this.guard = new RBAC({module: this.module});
      
    },
    addForm() {
      this.$router.push({
        name: this.isPlanBoardMode ? 'dynamicModules.formModal' : "dynamicModules.form",
        params: {moduleName: this.dynModuleName, action: "add"},
      });
    },
    deleteForms() {
      this.selected.forEach((e) => {
        new AbModel().delete(e.ID, this.module)
          .then((res) => {
            if(res === 'locked') {
              store.state.alertMessage = "lockedOnDelete";
            } else {
              store.state.alertMessage = res && res.error ? "error" :  "delete";
            }
            this.refreshList();
          });
      });
      this.selected = [];
    },
    refreshList() {
      this.togleRefreshList = !this.togleRefreshList;
    },
    onSelected(selected) {
      this.selected = selected;
    },
    showAddFilterForm() {
      this.showAddFilter = true;
    },
    showSettingsForm() {
      this.filterEditName = '';
      this.filterEditShared = false;
      this.filterEditLocked = false;
      this.filterEditSelect = null;
    },
    // create new filter by (+)
    saveFilterSettings() {
      const settings = {
        module: this.moduleToSaveSettings,
        filter: { ...this.filterSave },
      };
      settings.filter.locked = settings.filter.shared ? settings.filter.locked : false;
      settings.filter.columns = this.visibleColumns;
      settings.filter.filters = this.filtersSelectModel.filters ?? [];
      this.saveListFilter(settings).then((res) => {
        this.filterSave.name = '';
        this.filterEditShared = false;
        this.filterEditLocked = false;
        this.applyFilters({ ID: res.id});
      });
    },
    // save edited filter name from (+)
    editFilterSettings() {
      if (this.filterEditSelect && this.filterEditSelect.ID) {
        const settings = {
          module: this.moduleToSaveSettings,
          filter: { ID: this.filterEditSelect.ID, name: this.filterEditName, shared: this.filterEditShared, locked:  this.filterEditShared ? this.filterEditLocked : false},
        };
        this.saveListFilter(settings).then(() => {
          this.filterEditName = '';
          this.filterEditShared = false;
          this.filterEditLocked = false;
          this.getListSettings(this.moduleToSaveSettings);
        });
      }
    },
    // delete the filter from (+)
    deleteFilterSettings() {
      if (this.filterEditSelect && this.filterEditSelect.ID) {
        const settings = {
          module: this.moduleToSaveSettings,
          filter: { ...this.filterEditSelect },
        };
        this.deleteListFilter(settings).then(() => {
          this.getListSettings(this.moduleToSaveSettings);
        });
        this.filterEditName = '';
        this.filterEditShared = false;
        this.filterEditLocked = false;
        this.filterEditSelect = null;
      }
    },
    selectFilterToEdit() {
      this.filterEditName = this.filterEditSelect.name;
      this.filterEditShared = this.filterEditSelect.shared;
      this.filterEditLocked = this.filterEditSelect.locked ? true : false;
    },
    getFilterType(filter) {
      let ftype = this.$t('table.headers.filterPrivate');
      if (filter.shared) {
        ftype = filter.authorName ? this.$t('table.headers.filterSharedByUser') + ' ' + filter.authorName : this.$t('table.headers.filterShared');
      }
      return ftype;
    },
    onColumnDrop(pos) {
      if (this.isPlanBoardMode) {
        // -2 if we add first field ID
        pos.from -= 2; pos.to -= 2;
      }  else {
        pos.from -= 1; pos.to -= 1;
      }
      //const curColumns = [ ...this.filtersSelectModel.columns ];
      const curColumns = [ ...this.visibleColumns ];
      
      const val = curColumns[pos.from];
      curColumns.splice(pos.from, 1);
      curColumns.splice(pos.to, 0, val);
      this.visibleColumns = curColumns;
      
      this.saveColumnSettings('column');
      this.refreshList();
    },
    tableMenuClose () {
      this.$refs.settingsMenu.hide()
      this.$refs.tableHeaderMenu.hide()
    },
    getAllFields(fields1 = {}, fields2 = {}) {
      if (this.exDynFieldsAll) {
        const exDynFields = JSON.parse(JSON.stringify(this.exDynFieldsAll))
        for (const fName in exDynFields) {
          if (!fName.includes('.workOrders') && !fName.includes('.appointments')) {
            delete exDynFields[fName]
          }
        }
        return exDynFields
        
        //return this.exDynFieldsAll;
      } else {
        
        return {...this.dynFields, ...fields1, ...fields2};
      }
    },
    getAllColumnsForSelect() {
      const cols = [];
      const fields = ListValues.getValidColumns(this.getAllFields());
      for (const column in fields) {
        cols.push({name : column, label: fields[column].label || column});
      }
      return cols.sort(((a, b) => { return a.order - b.order }));
    },
    getSelectedString() {
      return this.selected.length === 0
        ? ""
        : `${this.selected.length} record${
          this.selected.length > 1 ? "s" : ""
        } ${this.$t("table.misc.selectedOf")} ${this.data.length}`;
    },
    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    },
  },
  beforeMount() {
    this.initRBAC();
    this.getListSettings(this.moduleToSaveSettings);
  },
  watch: {
    selected() {
      this.deleteBtnVisible = this.selected.length > 0;
    },
    module() {
      this.getListSettings(this.module);
      this.showTableRefresh += 1;
      this.initRBAC();
    }
  },
  computed: {
    ...mapState(["dynamicModules"]),
    ...mapState("settings", ["listSettings", "userFormsSettings"]),
    ...mapState("settings", ["currentListPagination"]),
    // RBAC
    isAddDisable() {
      
      return !this.guard.check('create');
    },
    isAddHidden() {
      return this.guard.hide('create');
    },
    isDeleteDisable() {
      return !this.guard.check('delete');
    },
    isDeleteHidden() {
      return this.guard.hide('delete');
    },
    columns() {
      const sortable = !this.columnSortEnabled;
      const cols = [];
      const fields = this.getAllFields();
      const locVisibleColumns = this.visibleColumns;

      

      if (locVisibleColumns) {
        locVisibleColumns.filter(c => fields[c]).map((column) => {
          const label = fields[column].label ? fields[column].label : column;
          const dateType = fields[column].type === 'date' || fields[column].type === 'datetime';
          const newCol = {
            name: column,
            align: this.align,
            label: label+'       ',
            field: column,
            type: fields[column].type
          };
          if (sortable) {
            newCol.sortable = sortable;
            if (dateType) {
              newCol.sort = (a, b) => {
                
                const d1 = moment(a, 'DD-MM-yyyy HH:mm');
                const d2 = moment(b, 'DD-MM-yyyy HH:mm');
                if (d1.isAfter(d2)) {
                  return 1;
                } else if (d1.isBefore(d2)) {
                  return -1;
                }
                return 0;
              };
            }
          }
          const searchColumn = column.split('.')[0];
          if ((this.dropDownValues && this.dropDownValues[searchColumn]) ||
            (fields[column] && fields[column].type === 'checkbox')) {
            newCol.filterType = 'select';
          }
          cols.push(newCol);
        });
      }
      
      return cols;
    },
    // get visible columns for DynamicList
    visibleColumns: {
      get() {
        const fields = this.getAllFields();
        const vColumns = this.filtersSelectModel ? this.filtersSelectModel.columns : [];
        const hiddenFields = []
        for (const f in this.dynFields) {
          if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
        }
        return vColumns.filter(e => !hiddenFields.includes(e)).filter(c => fields[c]);
      },
      set(val) {
        return this.filtersSelectModel.columns = [...val];
      }
    },
    // get visible columns for BaseSectionRelatedRecords, sub records and product lines
    visibleFormListColumns: {
      get() {
        const vColumns = this.userFormsSettings[this.moduleNameForFormSetting] && this.userFormsSettings[this.moduleNameForFormSetting].columns
          ? this.userFormsSettings[this.moduleNameForFormSetting].columns
          : [];
        const hiddenFields = []
        for (const f in this.dynFields) {
          if (this.dynFields[f].hidden) hiddenFields.push(this.dynFields[f].name);
        }
        
        return vColumns.filter(e => !hiddenFields.includes(e));
      }
    },
    module() {
      return this.dynModuleName;
    },
    dynModuleData() {
      return this.dynamicModules && this.dynamicModules[this.dynModuleName] ? this.dynamicModules[this.dynModuleName] : {} ;
    },
    dynTitle() {
      const translationKey = `modules.${this.dynModuleData.id}.pluralName`
      if(store.state.fieldTranslations && store.state.fieldTranslations[translationKey]) {
        return store.state.fieldTranslations[translationKey]
      }
      return this.dynModuleData ? this.dynModuleData.pluralName || this.dynModuleData.name : this.module;
    },
    dynSingularTitle() {
      return this.dynModuleData ? this.dynModuleData.singularName || this.dynModuleData.name : this.module;
    },
    dynFields() {
      return this.dynModuleData && this.dynModuleData.fields
        ? { ...ListValues.locFields(), ...this.dynModuleData.fields}
        : {};
    },
    allColumnsForSelect() {
      return this.getAllColumnsForSelect();
    },
    align() {
      return "left";
    },
    moduleToSaveSettings() {
      return this.moduleSettings || this.module;
    },
    dropDownValues() {
      return this.dynModuleData ? this.dynModuleData.dropDownValues : {};
    },
    divClass() {
      return this.isPlanBoardMode ? '' : 'q-px-lg q-py-sm';
    },
    moduleSettings() {
      return this.isPlanBoardMode ? 'planboard' : null;
    },
    filtersForQTable() {
      return [];
    },
    filtersOptions() {
      let filters = this.getFilters
      if(filters.length > 0) {
        filters = sortList(this.getFilters, "name")
      }
      return filters;
    },
    filtersOptionsEdit() {
      return this.getFilters.filter(e => !e.default && (!e.shared || (!e.locked || e.author === store.state.userID)));
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.showTableRefresh += 1;
    next();
  }
};
